<app-phx-panel headerTitle="Client Specific Fields">
    <app-typed-client-specific-fields [editable]="editable" *ngIf="showTypedClientSpecificFields$ | async">
    </app-typed-client-specific-fields>
    <div *ngIf="formGroup" class="form-horizontal">
        <form [formGroup]="formGroup">
            <div *ngFor="let field of fields">
                <div [ngSwitch]="field.FieldType">
                    <div *ngSwitchCase="'Textbox'">
                        <phx-form-control [control]="formGroup.controls[field.FieldName]"
                            [labelText]="field.DisplayName" [editable]="editable" [viewModeText]="field.FieldValue">
                            <dx-text-box [formControl]="formGroup.controls[field.FieldName]" 
                                [maxLength]="500"></dx-text-box>
                            <div class="error-inline" *ngIf="formGroup.controls[field.FieldName].invalid">
                                <span
                                    *ngIf="formGroup.controls[field.FieldName].errors.pattern">{{field.ValidationMessage}}</span>
                            </div>
                        </phx-form-control>
                    </div>
                    <div *ngSwitchCase="'DecimalNumber'">
                        <phx-form-control [control]="formGroup.controls[field.FieldName]"
                            [labelText]="field.DisplayName" [editable]="editable"
                            [viewModeText]="field.FieldValue| number : '1.2-2'">
                            <input [formControl]="formGroup.controls[field.FieldName]" type="text" class="form-control"
                                phxFloatBetweenInput='{"from":-9999999999999998, "to":9999999999999998, "decimalplaces":2}' />
                            <div class="error-inline" *ngIf="formGroup.controls[field.FieldName].invalid">
                                <span
                                    *ngIf="formGroup.controls[field.FieldName].errors.pattern">{{field.ValidationMessage}}</span>
                            </div>
                        </phx-form-control>
                    </div>
                    <div *ngSwitchCase="'Dropdown'">
                        <phx-form-control [control]="formGroup.controls[field.FieldName]"
                            [labelText]="field.DisplayName" [editable]="editable" [viewModeText]="field.FieldValue">
                            <app-phx-select-box [formControl]="formGroup.controls[field.FieldName]"
                                [items]="field.SelectValueArray"></app-phx-select-box>
                        </phx-form-control>
                    </div>
                    <div *ngSwitchCase="'Datepicker'">
                        <phx-form-control [control]="formGroup.controls[field.FieldName]"
                            [labelText]="field.DisplayName" [editable]="editable"
                            [viewModeText]="field.FieldValue | date:dateDisplayFormat">
                            <dx-date-box [formControl]="formGroup.controls[field.FieldName]" valueChangeEvent="keyup"
                                type="date" [dateSerializationFormat]="dateSerializationFormat">

                            </dx-date-box>
                        </phx-form-control>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-phx-panel>
