import { Component, Input } from '@angular/core';
import { PhxConstants } from '../common';
import { FormGroup } from '../common/ngx-strongly-typed-forms';
import { CustomField } from './model/custom-field';
import { ClientSpecificFieldsFormService } from './client-specific-fields-form.service';
import { WorkOrderFormService } from '../workorder/services';

@Component({
  selector: 'app-client-specific-fields',
  templateUrl: './client-specific-fields.component.html',
  styleUrls: ['./client-specific-fields.component.less']
})

export class ClientSpecificFieldsComponent {

  @Input() editable: boolean;
  @Input() fields: CustomField[] = [];

  formGroup: FormGroup<CustomField[]> = this.clientSpecificFieldsFormService.formGroup;
  showTypedClientSpecificFields$ = this.workOrderFormService.organizationHasFederalWorkers$;

  readonly dateSerializationFormat = PhxConstants.DateFormat.yyyy_MM_dd;
  readonly dateDisplayFormat = PhxConstants.DateFormat.mediumDate;

  constructor(
    private clientSpecificFieldsFormService: ClientSpecificFieldsFormService,
    private workOrderFormService: WorkOrderFormService
  ) { }
}
