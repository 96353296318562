import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { TypedClientSpecificFields } from './model/typed-client-specific-fields';
import { TypedClientSpecificFieldsFormService } from './typed-client-specific-fields-form.service';

@Component({
  selector: 'app-typed-client-specific-fields',
  templateUrl: './typed-client-specific-fields.component.html',
  styleUrls: ['./typed-client-specific-fields.component.less']
})
export class TypedClientSpecificFieldsComponent implements OnInit {
  @Input() editable: boolean;
  formGroup: FormGroup<TypedClientSpecificFields>;

  constructor(private typedClientSpecificFieldsFormService: TypedClientSpecificFieldsFormService) { }

  ngOnInit(): void {
    this.formGroup = this.typedClientSpecificFieldsFormService.formGroup;
  }
}
