<div class="form-horizontal">
    <form [formGroup]="formGroup">
        <phx-form-control [control]="formGroup.controls.IsFederalWorker" 
        labelText="Is a Federal Worker" [editable]="editable" [viewModeText]="formGroup.controls.IsFederalWorker.value | boolToYesNo">
            <fieldset>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsFederalWorker" type="radio" [value]="true"
                    [checked]="formGroup.controls.IsFederalWorker.value === true"
                    [formControl]="formGroup.controls.IsFederalWorker">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsFederalWorker" type="radio" [value]="false"
                    [checked]="formGroup.controls.IsFederalWorker.value === false"
                    [formControl]="formGroup.controls.IsFederalWorker">No</label>
            </fieldset>
        </phx-form-control>
        <phx-form-control [control]="formGroup.controls.IsControlledGoods"
        *ngIf="formGroup.controls.IsFederalWorker.value === true"
        labelText="Is Controlled Goods" [editable]="editable" [viewModeText]="formGroup.controls.IsControlledGoods.value | boolToYesNo">
            <fieldset>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsControlledGoods" type="radio" [value]="true"
                    [checked]="formGroup.controls.IsControlledGoods.value === true"
                    [formControl]="formGroup.controls.IsControlledGoods">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsControlledGoods" type="radio" [value]="false"
                    [checked]="formGroup.controls.IsControlledGoods.value === false"
                    [formControl]="formGroup.controls.IsControlledGoods">No</label>
            </fieldset>
        </phx-form-control>
    </form>
</div>